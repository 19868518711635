<template>
  <div class="profile">
    <dog-form
      ref="dogForm"
      :form-data="formData"
      :form-options="formOptions"
    ></dog-form>
    <el-button type="primary" @click="confirm" class="profile__btn"
      >保存修改</el-button
    >
  </div>
</template>

<script>
  import userService from '@/api/service/user.js';
  import fileService from '@/api/service/file';
  import uploadAvatar from '../../systemManage/userManage/uploadAvatar.vue';

  export default {
    name: 'profile',
    data() {
      return {
        uploaded: false,
        formData: {},
        formOptions: [
          {
            formItem: {
              label: '用户名',
              prop: 'userName'
            }
          },
          {
            type: 'input',
            formItem: {
              label: '真实姓名',
              prop: 'realName',
              rules: []
            }
          },
          {
            type: 'select',
            formItem: {
              label: '性别',
              prop: 'sex',
              rules: []
            },
            attrs: {
              service: this.$enumService('sex'),
              clearable: false
            }
          },
          {
            type: 'custom',
            formItem: {
              label: '头像',
              prop: 'avatar',
              rules: []
            },
            component: uploadAvatar,
            listeners: {
              input: () => {
                this.uploaded = true;
              }
            }
          },
          {
            type: 'input',
            formItem: {
              label: '个人简介',
              prop: 'remark',
              rules: []
            },
            attrs: {
              type: 'textarea',
              rows: 3
            }
          }
        ]
      };
    },
    mounted() {
      this.getUserDetail();
    },
    methods: {
      getUserDetail() {
        userService
          .getUserDetail({ id: this.$store.getters.userInfo.id })
          .then((res) => {
            this.formData = {
              ...res,
              avatar: {
                filePath: res.avatar
              }
            };
          });
      },
      confirm() {
        this.$refs.dogForm
          .validate()
          .then(() => {
            return this.uploaded
              ? fileService.upload(this.formData.avatar.form)
              : Promise.resolve();
          })
          .then((res) => {
            const formAvatar = this.formData.avatar
              ? this.formData.avatar.filePath
              : '';
            const params = {
              id: this.formData.id,
              realName: this.formData.realName,
              sex: this.formData.sex,
              avatar: res ? res.filePath : formAvatar,
              remark: this.formData.remark
            };
            return userService.editUser(params);
          })
          .then(() => {
            this.$store.dispatch('setUserInfo');
          });
      }
    }
  };
</script>

<style lang="scss" scoped>
  @include b(profile) {
    width: 400px;
    @include e(btn) {
      margin-left: 96px;
    }
  }
</style>
